import React, { useEffect, useState } from "react";
import * as firestore from "../services/firestore";
import { useSearchParams } from "react-router-dom";
import {
  Grid,
  Heading,
  Text,
  ResponsiveContext,
  Box,
  Button,
  Spinner,
  TextInput,
  Card,
  CardBody,
  ThemeContext,
  CheckBox,
  Select,
} from "grommet";
import { isSmallScreen } from "../helpers/ScreenHelpers";
import { GlobalHeader } from "../components/GlobalHeader";
import { GlobalFooter } from "../components/GlobalFooter";
import { IClinic, IClinicService } from "../services/types";
import _ from "lodash";
import { ServiceCard } from "../components/ServiceCard";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

interface IInquiryForm {
  firstName: string;
  lastName: string;
  email: string;
  isExpecting: boolean | undefined;
  isParent: boolean | undefined;
  hearAbout: string;
  city: string;
  isPaying: boolean | undefined;
}

export const MohmInquire2: React.FC = () => {
  const [clinic, setClinic] = useState<IClinic>({} as IClinic);
  const [services, setServices] = useState<IClinicService[]>([]);
  const [selectedService, setSelectedService] = useState<IClinicService>();
  const [inquiryFormValues, setInquiryFormValues] = useState<IInquiryForm>(
    {} as IInquiryForm
  );
  const [isIframe, setIsIframe] = useState<boolean>(false);

  const [isLoadingClinic, setIsLoadingClinic] = useState<boolean>(true);
  const [isBooking, setIsBooking] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const fetchClinicData = async () => {
    setIsLoadingClinic(true);
    try {
      const clinic = await firestore.getClinicByName("mohm-health");
      document.title = `Inquire - ${clinic.displayName}`;

      setIsIframe(searchParams.get("iframe") === "true");
      const requestedServiceIds = searchParams.get("serviceIds")?.split(",");

      let services: IClinicService[] = [];
      if (requestedServiceIds && !_.isEmpty(requestedServiceIds)) {
        await Promise.all(
          requestedServiceIds.map(async (serviceId) => {
            const service = await firestore.getServiceById(
              clinic.id,
              serviceId
            );
            services.push(service);
          })
        );
      }

      if (services.length === 1) {
        setSelectedService(services[0]);
      }
      setClinic(clinic);
      setServices(services);
    } catch (err) {
      console.log(`Error setting page data: ${err}`);
    }
    setIsLoadingClinic(false);
  };

  useEffect(() => {
    fetchClinicData();
  }, []);

  const updateInquiryForm = (values: any) => {
    setInquiryFormValues({ ...inquiryFormValues, ...values });
  };

  const validateForm = () => {
    if (
      !inquiryFormValues.firstName ||
      !inquiryFormValues.lastName ||
      !inquiryFormValues.email ||
      !inquiryFormValues.hearAbout ||
      !inquiryFormValues.city
    ) {
      toast.error("Oops! Please provide all required contact details.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }
    return true;
  };

  const createInquiry = async () => {
    if (!validateForm()) {
      return;
    }

    setIsBooking(true);
    const createRequest: firestore.ICreateLeadRequest = {
      clinicId: clinic.id,
      firstName: inquiryFormValues.firstName,
      lastName: inquiryFormValues.lastName,
      phoneNumber: {
        primary: "",
        home: "",
        work: "",
        cell: "",
      },
      email: inquiryFormValues.email,
      notes: `Is Expecting: ${
        inquiryFormValues.isExpecting || false
      } | Is Parent: ${
        inquiryFormValues.isParent || false
      } |  Referral Channel: ${inquiryFormValues.hearAbout} | City ${
        inquiryFormValues.city
      } | Willing to pay: ${inquiryFormValues.isPaying || false}`,
      ...(selectedService && { requestedServiceIds: [selectedService?.id] }),
    };

    await firestore.createLead(createRequest);
    setIsBooking(false);

    window.location.replace(`https://signup.${clinic.landingUrl}/thankyou`);
  };

  const PatientDataForm = () => {
    return (
      <ResponsiveContext.Consumer>
        {(size) => (
          <>
            <Heading level="3">Contact Details</Heading>
            <Card
              height="auto"
              width="100%"
              justify="center"
              align="center"
              margin="xsmall"
              elevation="medium"
              background="#FFF"
              style={{ transition: "all 0.2s" }}
            >
              <CardBody pad="medium">
                <Box
                  direction="row"
                  gap={isSmallScreen(size) ? "none" : "small"}
                  wrap
                >
                  <Box
                    direction="row"
                    gap="small"
                    margin={{ bottom: "medium" }}
                    width={isSmallScreen(size) ? "100%" : undefined}
                  >
                    <TextInput
                      size={isSmallScreen(size) ? "xsmall" : "small"}
                      placeholder="First Name *"
                      value={inquiryFormValues.firstName}
                      onChange={(event) =>
                        updateInquiryForm({ firstName: event.target.value })
                      }
                    />
                    <TextInput
                      size={isSmallScreen(size) ? "xsmall" : "small"}
                      placeholder="Last Name *"
                      value={inquiryFormValues.lastName}
                      onChange={(event) =>
                        updateInquiryForm({ lastName: event.target.value })
                      }
                    />
                  </Box>
                  <Box
                    direction="row"
                    gap="small"
                    margin={{ bottom: "medium" }}
                    width={isSmallScreen(size) ? "100%" : undefined}
                  >
                    <TextInput
                      size={isSmallScreen(size) ? "xsmall" : "small"}
                      placeholder="Email Address *"
                      value={inquiryFormValues.email}
                      onChange={(event) =>
                        updateInquiryForm({ email: event.target.value })
                      }
                    />
                  </Box>
                </Box>
                <Box direction="row" gap="small" margin={{ bottom: "medium" }}>
                  <CheckBox
                    checked={inquiryFormValues.isExpecting}
                    label={
                      <Text size="small">
                        Are you or your partner expecting?
                      </Text>
                    }
                    onChange={(event) =>
                      updateInquiryForm({ isExpecting: event.target.checked })
                    }
                  />
                </Box>
                <Box direction="row" gap="small" margin={{ bottom: "medium" }}>
                  <CheckBox
                    checked={inquiryFormValues.isParent}
                    label={<Text size="small">Are you already a parent?</Text>}
                    onChange={(event) =>
                      updateInquiryForm({ isParent: event.target.checked })
                    }
                  />
                </Box>
                <Text size="small">How did you hear about us? *</Text>
                <Box direction="row" gap="small" margin={{ bottom: "medium" }}>
                  <Select
                    size={isSmallScreen(size) ? "xsmall" : "small"}
                    options={[
                      "Ad",
                      "Friend",
                      "Social Media",
                      "Pediatrician",
                      "Other",
                    ]}
                    value={inquiryFormValues.hearAbout}
                    onChange={({ option }) =>
                      updateInquiryForm({ hearAbout: option })
                    }
                  />
                </Box>
                <Text size="small">In which city are you located? *</Text>
                <Box direction="row" gap="small" margin={{ bottom: "medium" }}>
                  <TextInput
                    size={isSmallScreen(size) ? "xsmall" : "small"}
                    placeholder="City"
                    value={inquiryFormValues.city}
                    onChange={(event) =>
                      updateInquiryForm({ city: event.target.value })
                    }
                  />
                </Box>
                <Box direction="row" gap="small" margin={{ bottom: "medium" }}>
                  <CheckBox
                    checked={inquiryFormValues.isPaying}
                    label={
                      <Text size="small">
                        Would you like to be added to our pediatrician-moderated
                        slack channel?
                        <br /> Cost is $20. Payment link to be sent separately.
                      </Text>
                    }
                    onChange={(event) =>
                      updateInquiryForm({ payment: event.target.checked })
                    }
                  />
                </Box>
                <Box align="center">
                  <Button
                    primary
                    label={
                      <Box direction="row">
                        {isBooking && (
                          <Spinner
                            color="#FFF"
                            size="xsmall"
                            margin={{ left: "-5px", right: "10px" }}
                          />
                        )}
                        <Text weight="bold" textAlign="center" color="white">
                          Join Now
                        </Text>
                      </Box>
                    }
                    onClick={createInquiry}
                    disabled={isBooking}
                  />
                </Box>
              </CardBody>
            </Card>
          </>
        )}
      </ResponsiveContext.Consumer>
    );
  };

  const MainBody = () => {
    return (
      <ResponsiveContext.Consumer>
        {(size) => (
          <Box
            width={{ width: "100%", max: "1000px" }}
            pad={{
              horizontal: isSmallScreen(size) ? "medium" : "large",
            }}
            justify="center"
          >
            <ToastContainer />
            {!_.isEmpty(services) && (
              <>
                <Heading level="3">Select Service</Heading>

                <Box
                  direction="row"
                  wrap={true}
                  justify={selectedService ? "start" : "center"}
                >
                  {selectedService ? (
                    <ServiceCard
                      service={selectedService}
                      isActive={true}
                      selectCallback={setSelectedService}
                    />
                  ) : (
                    services.map((service) => (
                      <ServiceCard
                        service={service}
                        isActive={selectedService === service}
                        selectCallback={setSelectedService}
                      />
                    ))
                  )}
                </Box>
              </>
            )}

            {(selectedService || _.isEmpty(services)) && PatientDataForm()}
          </Box>
        )}
      </ResponsiveContext.Consumer>
    );
  };

  return (
    <ThemeContext.Extend value={(clinic.theme as any) || {}}>
      <ResponsiveContext.Consumer>
        {(size) =>
          isLoadingClinic ? (
            <Box
              align="center"
              alignSelf="center"
              justify="center"
              fill="vertical"
            >
              <Spinner size="medium" />
            </Box>
          ) : isIframe ? (
            <Box align="center" alignSelf="center" justify="center">
              {MainBody()}
            </Box>
          ) : (
            <Grid
              rows={["xsmall", "flex", ["medium", "450px"]]}
              columns={[["small", "flex"]]}
              areas={[
                { name: "header", start: [0, 0], end: [1, 0] },
                { name: "main", start: [0, 1], end: [1, 1] },
                { name: "footer", start: [0, 2], end: [1, 2] },
              ]}
              fill="horizontal"
              justify="center"
              style={{ overflow: "hidden" }}
            >
              <GlobalHeader clinic={clinic} />
              {MainBody()}
              <GlobalFooter clinic={clinic} />
            </Grid>
          )
        }
      </ResponsiveContext.Consumer>
    </ThemeContext.Extend>
  );
};
