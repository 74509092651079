import React from "react";
import { Card, CardBody, ResponsiveContext, Text } from "grommet";
import { isSmallScreen } from "../helpers/ScreenHelpers";
import { IClinicService } from "../services/types";

type IProps = {
  service: IClinicService;
  isActive: boolean;
  selectCallback: (service: IClinicService | undefined) => void;
};

export const ServiceCard: React.FC<IProps> = ({
  service,
  isActive,
  selectCallback,
}: IProps) => {
  return (
    <ResponsiveContext.Consumer>
      {(size) => (
        <Card
          height="auto"
          width={isSmallScreen(size) ? "100%" : "medium"}
          margin={"medium"}
          elevation="small"
          background={isActive ? "brand" : "#FFF"}
          hoverIndicator={{ elevation: "large" }}
          focusIndicator={false}
          style={{ transition: "all 0.2s" }}
          onClick={() =>
            isActive ? selectCallback(undefined) : selectCallback(service)
          }
        >
          <CardBody pad="medium">
            <Text
              size="16px"
              weight="bold"
              margin={{ bottom: "5px" }}
              color={isActive ? "background" : "#000"}
            >
              {service.name}
              <Text
                size="14px"
                weight="normal"
              >{`  (${service.durationMins} mins)`}</Text>
            </Text>
            <Text size="16px" color={isActive ? "background" : "#000"}>
              {service.description}
            </Text>
          </CardBody>
        </Card>
      )}
    </ResponsiveContext.Consumer>
  );
};
