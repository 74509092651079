export const DATE_FORMAT = "YYYY-MM-DD";

//#FFF8F5 superlight beige used for the old bg
export const DEFAULT_THEME = {
  global: {
    colors: {
      brand: "#F46036",
      lightBrand: "#FF8F60",
      veryLightBrand: "#FFC6B6",
      grey: "#9DB0CD",
      lightBg: "#1D1A27",
      background: "#FFF",
    },
    breakpoints: {
      small: {
        value: 768,
        borderSize: {
          xsmall: "1px",
          small: "2px",
          medium: "4px",
          large: "6px",
          xlarge: "12px",
        },
        edgeSize: {
          none: "0px",
          hair: "1px",
          xxsmall: "2px",
          xsmall: "3px",
          small: "6px",
          medium: "12px",
          large: "24px",
          xlarge: "48px",
        },
        size: {
          xxsmall: "24px",
          xsmall: "48px",
          small: "96px",
          medium: "192px",
          large: "384px",
          xlarge: "768px",
          full: "100%",
        },
      },
      medium: { value: 1300 },
      large: {},
    },
    focus: {
      border: {
        color: {
          light: "brand",
          dark: "grey",
        },
      },
    },
  },
  button: {
    default: {
      background: { color: "brand", opacity: 1 },
      border: { color: "brand" },
      color: "brand",
    },
    primary: {
      background: { color: "brand", opacity: 1 },
      border: { color: "brand" },
      color: "brand",
    },
  },
};
