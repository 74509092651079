import React from "react";
import { Box, Spinner } from "grommet";

export const Loading: React.FC = () => {
  return (
    <Box align="center" alignSelf="center" justify="center" fill="vertical">
      <Spinner size="medium" />
    </Box>
  );
};
