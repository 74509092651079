import { DocumentSnapshot, Timestamp } from "@firebase/firestore-types";
import firebase from "firebase/app";
import "@firebase/firestore";
import { IClinic, IClinicService, IHealthCard, IPhoneNumber } from "./types";
import _ from "lodash";

export const db = firebase.firestore();

export const FUNCTIONS = {
  getCreateLeadCallable: () =>
    firebase.functions().httpsCallable("httpsOnCallLeads-create"),
  getCreatePatientAndAppointmentCallable: () =>
    firebase
      .functions()
      .httpsCallable("httpsOnCallBookingCreatePatientAndAppointment-default"),
};

export interface IBookingCreatePatientAndAppointmentRequest {
  clinicId: string;
  firstName: string;
  lastName: string;
  phoneNumber: IPhoneNumber;
  dateOfBirth: string;
  healthCard: IHealthCard;
  email: string;
  serviceId: string;
  startAt: Timestamp;
}

export interface ICreateLeadRequest {
  clinicId: string;
  firstName: string;
  lastName: string;
  phoneNumber: IPhoneNumber;
  email: string;
  notes?: string;
  requestedServiceIds?: string[];
}

export const getClinicByName = async (name: string): Promise<IClinic> => {
  const result = await db
    .collection("clinics")
    .where("name", "==", name)
    .limit(1)
    .get();
  const clinic = result.docs.map((doc: any) => doc.data())[0];

  return clinic;
};

export const getServiceById = async (
  clinicId: string,
  serviceId: string
): Promise<IClinicService> => {
  try {
    const query = await db
      .collection("clinics")
      .doc(clinicId)
      .collection("clinic-services")
      .doc(serviceId)
      .get();

    let service = query.data() as IClinicService;

    return service;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAllServicesForClinic = async (
  clinicId: string
): Promise<IClinicService[]> => {
  try {
    const query = await db
      .collection("clinics")
      .doc(clinicId)
      .collection("clinic-services")
      .orderBy("name", "asc")
      .get();

    let services = query.docs.map((doc: DocumentSnapshot) =>
      doc.data()
    ) as IClinicService[];

    services = services.filter((service) => !service?.deletedAt);

    return services;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getServiceOpenings = async (
  clinicId: string,
  serviceId: string
) => {
  try {
    const body = { clinicId, serviceId };

    const response = await fetch(
      "https://journey-backend-4zbbdaoy3a-pd.a.run.app/",
      {
        method: "post",
        body: JSON.stringify(body),
        headers: { "Content-Type": "application/json" },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createPatientAndAppointment = async (
  req: IBookingCreatePatientAndAppointmentRequest
) => {
  try {
    const createPatientAndAppointment =
      FUNCTIONS.getCreatePatientAndAppointmentCallable();
    await createPatientAndAppointment(req);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createLead = async (req: ICreateLeadRequest) => {
  try {
    const createLead = FUNCTIONS.getCreateLeadCallable();
    await createLead(req);
  } catch (error) {
    return Promise.reject(error);
  }
};
