import React from "react";
import { Image, Header, Heading, ResponsiveContext } from "grommet";
import { IClinic } from "../services/types";

type IProps = {
  clinic: IClinic;
};

export const GlobalHeader: React.FC<IProps> = ({ clinic }: IProps) => {
  return (
    <ResponsiveContext.Consumer>
      {(size) => (
        <Header
          justify="between"
          gridArea="header"
          pad={{
            horizontal: "medium",
          }}
          width={{ width: "100%", max: "1400px" }}
        >
          <a
            href={`https://www.${clinic.landingUrl}`}
            style={{ textDecoration: "none" }}
          >
            {clinic.logoUrl ? (
              <Image src={clinic.logoUrl} width="150px" />
            ) : (
              <Heading color="brand" level={3}>
                {clinic.displayName}
              </Heading>
            )}
          </a>
        </Header>
      )}
    </ResponsiveContext.Consumer>
  );
};
