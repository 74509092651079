import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "firebase/auth";
import firebase from "./services/firebase";
import { DEFAULT_THEME } from "./consts";
import { Grommet } from "grommet";
import { Book } from "./pages/Book";
import { Loading } from "./pages/Loading";
import { Inquire } from "./pages/Inquire";
import { MohmInquire1 } from "./pages/MohmInquire1";
import { MohmInquire2 } from "./pages/MohmInquire2";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";

function App() {
  const [isInitialized, setIsInitialized] = useState(false);

  firebase.auth().onAuthStateChanged((_user: any) => {
    setIsInitialized(true);
  });

  return (
    <Grommet full={true} theme={DEFAULT_THEME}>
      {isInitialized ? (
        <BrowserRouter>
          <Routes>
            <Route
              path="/privacy-policy/:clinicName"
              element={<PrivacyPolicy />}
            />
            <Route path="/mohm-health/inquire2" element={<MohmInquire2 />} />
            <Route path="/mohm-health/inquire1" element={<MohmInquire1 />} />
            <Route path="/:clinicName/inquire" element={<Inquire />} />
            <Route path="/:clinicName" element={<Book />} />
          </Routes>
        </BrowserRouter>
      ) : (
        <Loading />
      )}
    </Grommet>
  );
}

export default App;
