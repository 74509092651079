import React, { useEffect, useState } from "react";
import {
  Box,
  DataTable,
  Grid,
  Heading,
  Main,
  ResponsiveContext,
  Text,
} from "grommet";
import { isSmallScreen } from "../helpers/ScreenHelpers";
import { GlobalHeader } from "../components/GlobalHeader";
import { GlobalFooter } from "../components/GlobalFooter";
import { useParams } from "react-router-dom";
import * as firestore from "../services/firestore";
import { IClinic } from "../services/types";

export const PrivacyPolicy: React.FC = () => {
  const { clinicName } = useParams();
  const [clinic, setClinic] = useState<IClinic>({} as IClinic);

  const fetchClinicData = async () => {
    try {
      const clinic = await firestore.getClinicByName(clinicName || "");
      document.title = `Privacy Policy - Journey Health`;

      setClinic(clinic);
    } catch (err) {
      console.log(`Error setting page data: ${err}`);
    }
  };

  useEffect(() => {
    fetchClinicData();
  }, [clinicName]);

  const Body = () => {
    return (
      <ResponsiveContext.Consumer>
        {(size) => (
          <Main
            gridArea="main"
            direction="column"
            align="center"
            justify="center"
            width="100vw"
          >
            <Heading>Privacy Policy</Heading>
            <Box
              direction="column"
              wrap={true}
              width={{ width: "100%", max: "1400px" }}
              justify="center"
              align="start"
              margin={{
                bottom: "xlarge",
                horizontal: isSmallScreen(size) ? "xsmall" : "xlarge",
              }}
            >
              <Heading level={3} textAlign="start">
                Patient Privacy Policy
              </Heading>
              <Heading level={3} textAlign="start">
                1. Our approach to privacy
              </Heading>
              <Text margin={{ bottom: "15px" }}>
                1.1 Journey Health Technologies Inc. (“Journey Health“, “we“,
                “our“, or “us“) is committed to protecting your privacy. This
                privacy policy (the “Policy”) sets out how we collect, store,
                process, transfer, share and use data, including data we collect
                about you.
              </Text>
              <Text margin={{ bottom: "15px" }}>
                1.2 Unless otherwise indicated, capitalized words and
                expressions appearing in this Policy shall be interpreted or
                construed as follows:
              </Text>
              <Text margin={{ bottom: "15px" }}>
                (a) “Consultation” has the meaning set out in Section 4.2;
              </Text>
              <Text margin={{ bottom: "15px" }}>
                (b) “Dependent” has the meaning set out in Section 12.1;
              </Text>
              <Text margin={{ bottom: "15px" }}>
                (c) “Health Service Provider” means a person that is licensed or
                permitted by a regulatory authority to provide certain Health
                Services through our Platform in the Jurisdiction in which you
                are located at the time of your Consultation;
              </Text>
              <Text margin={{ bottom: "15px" }}>
                (d) “Health Service” means a service delivered by a Health
                Service Provider to you through the use of our Services and
                Platform for any of the following purposes: (i) protecting,
                promoting or maintaining physical and mental health; (ii)
                preventing illness; (iii) diagnosing and treating illness; or
                (iv) rehabilitation.
              </Text>
              <Text margin={{ bottom: "15px" }}>
                (e) “Jurisdiction” means a Province or Territory in Canada, or a
                State in the USA;
              </Text>
              <Text margin={{ bottom: "15px" }}>
                (f) “Law” means a federal, provincial, state or municipal law,
                any regulation, ordinance, code, guideline, policy, or rule of
                civil or common law, any trade amendment thereto and any
                judicial or administrative order, consent, decree or judgment
                and any reference to a repealed statute shall be deemed to be a
                reference to such a statute and its related regulations as they
                existed prior to said statute being repealed;
              </Text>
              <Text margin={{ bottom: "15px" }}>
                (g) “Other Personal Information” means Personal Information
                which is not considered Personal Health Information;
              </Text>
              <Text margin={{ bottom: "15px" }}>
                (h) “Personal Health Information” means information about you:
              </Text>
              <Text margin={{ bottom: "15px" }}>
                (i) concerning your mental or physical health; (ii) concerning
                any Health Service provided to you; (iii) collected in the
                course of providing you with Health Services; (iv) collected
                incidentally to the provision of Health Services to you; or (v)
                such other Personal Information as may be deemed to be of a
                medical nature pursuant to applicable Privacy Laws; (vi)
                “Personal Information” means all information about you which is
                collected, created, compiled, used, disclosed or otherwise
                transmitted and/or stored on or by means of our Platform;
              </Text>
              <Text margin={{ bottom: "15px" }}>
                (j) “Platform” means the hardware, software, applications,
                websites and content owned and/or operated by us used for the
                provision of Services to you and Health Service Providers;
              </Text>
              <Text margin={{ bottom: "15px" }}>
                (k) “Privacy Laws” means the applicable legislation governing
                the collection, user, disclosure and/or protection of Personal
                Information and/or Personal Health Information in the Province,
                Territory, or State in which you reside at the time of your
                Consultation;
              </Text>
              <Text margin={{ bottom: "15px" }}>
                (l) “Services” shall mean any software or services provided by
                Journey Health, including but not limited to, billing, the
                provision of remote health and other services by Health Services
                Providers through use of interactive text, audio and video
                technology.
              </Text>
              <Text margin={{ bottom: "15px" }}>
                1.3 This Policy applies to Services accessed through our
                Platform.
              </Text>
              <Text margin={{ bottom: "15px" }}>
                1.4 Before accessing or using our Platform, please ensure that
                you have read and understood our collection, storage, use and
                disclosure of your Personal Information as described in this
                Policy. By accessing our Platform or using our Services, you are
                accepting and consenting to the practices described in this
                Policy.
              </Text>
              <Text margin={{ bottom: "15px" }}>
                1.5 Note that this Policy does not apply to the collection, use,
                retention, disposal, destruction, and protection of Personal
                Information (including Personal Health Information) by Health
                Service Providers. Health Service Providers are subject to
                Privacy Laws and professional requirements that govern their
                management of Personal Information THAT they collect, use and
                retain in the course of providing HEALTH services TO YOU through
                our Platform. The Health Services provided to you by your Health
                Service Provider forms a separate bilateral contractual
                relationship between you and the Health Service Provider and we
                are not parties to this agreement. Please feel free to ask any
                Health Service Provider to whom you are referred if you have
                questions about how he or she will treat your Personal
                Information.
              </Text>
              <Heading level={3} textAlign="start">
                2. Why we collect Personal Information and how we collect
                Personal Information
              </Heading>
              <Text margin={{ bottom: "15px" }}>
                2.1 We collect and process Personal Information about you when
                we need to do so to fulfil a contractual obligation (for
                example, use of your Health Card information to facilitate
                billing through your provincially funded health insurance
                program) or where we or someone we work with needs to use your
                Personal Information for a reason related to their business (for
                example, to allow a Health Service Provider to provide Health
                Services to you).
              </Text>
              <Text margin={{ bottom: "15px" }}>
                2.2 We only collect and process Personal Information after
                considering the risks to your privacy – for example, by
                providing clear transparency into our privacy practices,
                offering you control over your Personal Information where
                appropriate, limiting the information we keep, limiting what we
                do with your information, who we send your information to, how
                long we keep your information, or the technical measures we use
                the protect your information.
              </Text>
              <Text margin={{ bottom: "15px" }}>
                2.3 We obtain Personal Information about you from: (a)
                information provided to us directly by you; (b) information
                provided to us by third-parties, including Health Services
                Providers; and (c) through tracking technologies such as
                cookies.
              </Text>
              <Text margin={{ bottom: "15px" }}>
                2.4 If you choose not to provide Personal Information, we may
                not be able to provide our Services to you or respond to your
                other requests.
              </Text>
              <Heading level={3} textAlign="start">
                3. Information you give to us directly.
              </Heading>
              <Text margin={{ bottom: "15px" }}>
                3.1 We collect Personal Information about you directly from you
                when you voluntarily submit information directly to us by
                filling in forms on our Platform or by corresponding with us by
                phone, email or other means. This includes information you
                provide when you book an appointment with a Health Services
                Provider, participate in any discussion boards, forums or other
                social media functions on our Platform and when you report a
                problem with our Platform, or use some of our Services which may
                be made available to you from time to time.
              </Text>
              <Text margin={{ bottom: "15px" }}>
                3.2 More specifically, we will also collect certain Personal
                Health Information from you if you use our Services. The
                Personal Health Information we collect, and process is used
                solely in support of the Services that we provide. Except as may
                be otherwise required by law:
              </Text>
              <Text margin={{ bottom: "15px" }}>
                a. we shall not use any Personal Health Information except in
                the course of providing Services to you;
              </Text>
              <Text margin={{ bottom: "15px" }}>
                b. we shall not permit our employees or any person acting on our
                behalf to be able to have access to your Personal Health
                Information unless such person agrees to comply with the terms
                set out in this Policy and such other internal policies and
                procedures as we may implement and mandate for our employees or
                any person acting on our behalf.
              </Text>
              <Text margin={{ bottom: "15px" }}>
                3.3 The table below sets out the categories of Personal
                Information we collect directly from you and how we use that
                information. The table also lists the legal basis which we rely
                on to process the Personal Information and information as to how
                we determine applicable retention periods for the Personal
                Information provided to us.
              </Text>
              <DataTable
                columns={[
                  {
                    align: "start",
                    verticalAlign: "top",
                    property: "cat",
                    header: (
                      <Heading level={4}>
                        Category of Personal Information
                      </Heading>
                    ),
                  },
                  {
                    align: "start",
                    verticalAlign: "top",
                    property: "type",
                    header: <Heading level={4}>Type</Heading>,
                  },
                  {
                    align: "start",
                    verticalAlign: "top",
                    property: "how",
                    header: <Heading level={4}>How we use it</Heading>,
                  },
                  {
                    align: "start",
                    verticalAlign: "top",
                    property: "legal",
                    header: (
                      <Heading level={4}>Legal basis for processing</Heading>
                    ),
                  },
                ]}
                data={[
                  {
                    cat: "Contact information and basic personal details, such as your name, phone number, address, location, IP address, e-mail address",
                    type: "Other Personal Information.",
                    how: "We use this information to communicate with you, including sending statements, news, alerts and marketing communications. We use this information to deal with inquiries and other requests made by or about you, including customer service issues, relating to Services we provide to you and your Health Service Provider(s)",
                    legal:
                      "The processing is necessary for our legitimate interests, namely for marketing purposes, and for communicating with you effectively, for responding to your queries and for operation of our Services.",
                  },
                  {
                    cat: "Correspondence and comments. When you contact us directly, e.g. by email, phone, mail, or when you interact with customer service, we will record your comments and opinions.",
                    type: "Other Personal Information.",
                    how: "To address your questions, issues, and concerns and resolve your customer service issues.",
                    legal:
                      "The processing is necessary for our legitimate interests, namely communicating with you effectively for the purposes of resolving your issues.",
                  },
                  {
                    cat: "Health card number.",
                    type: "Personal Health Information",
                    how: "For purposes related to the provision of provincially funded health services to you.",
                    legal:
                      "The processing is necessary to bill your provincially funded health plan for covered Health Services provided to you by a Health Services Provider",
                  },
                  {
                    cat: "Basic Medical Information.",
                    type: "Personal Health Information",
                    how: "To connect and match you with a qualified Health Services Provider",
                    legal:
                      "The processing is necessary for our legitimate interest, namely for the operation of our Services.",
                  },
                  {
                    cat: "All Personal Information set out above.",
                    type: "Other Personal Information and Personal Health Information as more particularly set out above.",
                    how: "We will use all the Personal Information we collect to operate, maintain and provide to you the features and functionality of the Services, to monitor and improve our Services, our Platform and business, for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes, to keep the Platform safe and secure and to help us develop new products and services.",
                    legal: "See above.",
                  },
                ]}
              />
              <Heading level={3} textAlign="start">
                4. Information we receive from third parties
              </Heading>
              <Text margin={{ bottom: "15px" }}>
                4.1 We may receive Personal Information about you from third
                parties. We may receive Personal Information about you if you
                use any of the other websites we operate or the other services
                we provide from time to time. We also work closely with third
                parties (including, for example, the Government of the Province
                of Ontario, Ontario Telemedicine Network, subcontractors in
                technical, payment and delivery services, advertising networks,
                analytics providers, search information providers) and may
                receive information about you from them.
              </Text>
              <Text margin={{ bottom: "15px" }}>
                4.2 We may also receive Personal Information, including Personal
                Health Information, about you from your Health Service
                Providers. Our Platform allows you to access Health Services
                from Health Service Providers, including but not limited to
                physicians, psychologists, dietician/nutritionists via
                interactive text, audio, video or other technology which may be
                made available to you from time to time to facilitate the
                delivery of remote medical services to you (each such access of
                Health Services being a “Consultation”). During your
                Consultation, you Health Service Provider will collect Personal
                Information (including Personal Health Information) about you
                and use that information in order to provide you with Health
                Services. Your Health Service Provider may also provide us with
                Personal Information he or she collects in order to further
                facilitate the delivery of Health Services to you via our
                Platform.
              </Text>
              <Text margin={{ bottom: "15px" }}>
                4.3 Health Service Providers may collect Personal Health
                Information about you verbally or by text, including the reason
                for your consultation request, relevant health history and
                present condition or symptoms.
              </Text>
              <Text margin={{ bottom: "15px" }}>
                4.4 The Health Service Provider may access Personal Health
                Information that you have entered through our Platform and
                medical records or information created during earlier
                interactions through our Platform with our Health Service
                Providers.
              </Text>
              <Text margin={{ bottom: "15px" }}>
                4.5 Health Service Providers must comply with professional
                regulatory requirements, including as it relates to
                confidentiality and privacy and record keeping, as well as
                Privacy Laws.
              </Text>
              <Text margin={{ bottom: "15px" }}>
                4.6 Health Service Providers may create information such as
                prescriptions, sick notes and other notes about your
                Consultation.
              </Text>
              <Text margin={{ bottom: "15px" }}>
                4.7 We also provide a number of optional services where we are
                authorized by your Health Service Provider to do so. For
                example, we may receive your Personal Health Information from
                your Health Service Provider and use that information to: (a)
                securely fax a prescription to your chosen pharmacy; (b) obtain
                your lab results on behalf of the Health Service Provider which
                prescribed the lab tests; or (c) share the Personal Health
                Information provided to us by your Health Service Provider with
                other medical professionals (such as other Health Service
                Providers within our network, a specialist or your family
                doctor).
              </Text>
              <Text margin={{ bottom: "15px" }} weight="bold">
                4.8 Except as may otherwise be provided by Law, we are not the
                custodians of Personal Information we receive about you from
                your Health Services Providers. The Personal Information about
                you we receive from your Health Service Provider is Personal
                Information under the control of the Health Service Provider. We
                do not have the authority to use or disclose this information
                for any purpose other than the purpose for which the Health
                Service Provider has authorized us to use the information or for
                the purpose of carrying out a statutory or legal duty.
              </Text>
              <Text margin={{ bottom: "15px" }}>
                4.9 Where we do receive Personal Information about you from a
                Health Service Provider, we hold this information on behalf of
                you and the Health Service Provider and do so solely in our
                capacity as a provider of Services to you and the Health Service
                Provider. Except as otherwise set out in this Policy and the
                Terms of Use or as otherwise required by Law:
              </Text>
              <Text margin={{ bottom: "15px" }}>
                (a) we shall not use any Personal Health Information we receive
                except as necessary in the course of providing our Services to
                you and the Health Service Provider;
              </Text>
              <Text margin={{ bottom: "15px" }}>
                (b) we shall not disclose any Personal Health Information except
                as directed by your Health Service Provider; and
              </Text>
              <Text margin={{ bottom: "15px" }}>
                (c) we shall not permit our employees or any person acting on
                our behalf to be able to have access to your Personal Health
                Information unless such person agrees to comply with the terms
                set out in this Policy and such other internal policies and
                procedures as we may implement and mandate for our employees or
                any person acting on our behalf from time to time.
              </Text>
              <Heading level={3} textAlign="start">
                5. Use and collection of Personal Information through tracking
                technologies.
              </Heading>
              <Text margin={{ bottom: "15px" }}>
                5.1 We may also automatically collect Other Personal Information
                about you indirectly about how you access and use the Platform
                and information about the device you use to access the Platform.
              </Text>
              <Text margin={{ bottom: "15px" }}>
                5.2 We use cookies and similar tracking technologies to track
                the activity on our Services and store certain information.
                Tracking technologies used are beacons, tags, and scripts to
                collect and track information and to improve and analyze our
                Services. You can instruct your browser to refuse all Cookies or
                to indicate when a Cookie is being sent. However, if you do not
                accept cookies, you may not be able to use some parts of our
                Services.
              </Text>
              <Text margin={{ bottom: "15px" }}>
                5.3 Cookies can be “Persistent” or “Session” cookies. Persistent
                cookies remain on your personal computer or mobile device when
                you go offline, while Session cookies are deleted as soon as you
                close your web browser. <br />
                <br />
                We use both session and persistent cookies for the purposes set
                out below:
              </Text>
              <DataTable
                columns={[
                  {
                    align: "start",
                    verticalAlign: "top",
                    property: "cat",
                    header: <Heading level={4}>Category</Heading>,
                  },
                  {
                    align: "start",
                    verticalAlign: "top",
                    property: "type",
                    header: <Heading level={4}>Type</Heading>,
                  },
                  {
                    align: "start",
                    verticalAlign: "top",
                    property: "who",
                    header: <Heading level={4}>Administered by</Heading>,
                  },
                  {
                    align: "start",
                    verticalAlign: "top",
                    property: "purpose",
                    header: <Heading level={4}>Purpose</Heading>,
                  },
                ]}
                data={[
                  {
                    cat: "Necessary/ Essential Cookies",
                    type: "Session cookies",
                    who: "Us",
                    purpose:
                      "These cookies are essential to provide you with Services available through our Platform and to enable you to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these cookies, the Services that you have asked for cannot be provided, and we only use these cookies to provide you with those Services.",
                  },
                  {
                    cat: "Policy / Notice Acceptance Cookies",
                    type: "Persistent cookies",
                    who: "Us",
                    purpose:
                      "These Cookies identify if users have accepted the use of cookies on the Platform.",
                  },
                  {
                    cat: "Functionality Cookies",
                    type: "Persistent cookies",
                    who: "Us",
                    purpose:
                      "These cookies allow us to remember choices you make when you use the Platform, such as remembering your login details or language preference. The purpose of these cookies is to provide you with a more personal experience and to avoid you having to re-enter your preferences every time you use the Platform.",
                  },
                  {
                    cat: "Tracking and Performance cookies",
                    type: "Persistent Cookies",
                    who: "Third Parties",
                    purpose:
                      "The information gathered via these cookies may directly or indirectly identify you as an individual visitor. This is because the information collected is typically linked to a pseudonymous identifier associated with the device you use to access the Platform. We may also use these cookies to test new advertisements, pages, features or new functionality of the Platform to see how our users react to them.",
                  },
                ]}
              />
              <Text margin={{ bottom: "15px" }}>
                5.4 Please note that third parties (including, for example,
                advertising networks and providers of external services like web
                traffic analysis services) may also use cookies, over which we
                have no control. These cookies are likely to be
                analytical/performance cookies or targeting cookies.
              </Text>
              <Text margin={{ bottom: "15px" }}>
                5.5 We partner with third parties to manage our advertising on
                other sites. Our third-party partners may use technologies such
                as cookies to gather information about your activities on this
                Platform and other sites in order to provide you advertising
                based upon your browsing activities and interests.
              </Text>
              <Text margin={{ bottom: "15px" }}>
                5.7 Our Platform includes social media features, such as
                Facebook and Twitter. These providers may collect your IP
                address, which page you are visiting on our Platform, and may
                set a cookie to enable the feature to function properly. Your
                interactions with these providers are governed by the Policy of
                the company providing it.
              </Text>
              <Text margin={{ bottom: "15px" }}>
                5.8 We may link or combine the Personal Information we collect
                and/or receive about you and the information we collect
                automatically. This allows us to provide you with a personalized
                experience regardless of how you interact with us.
              </Text>
              <Text margin={{ bottom: "15px" }}>
                5.9 We may anonymize and aggregate any of the Personal
                Information we collect (so that it does not identify you). We
                may use anonymized information for purposes that include testing
                our IT systems, research, data analysis, improving our Services
                and developing new products and features. We may also share such
                anonymized information with others.
              </Text>
              <Heading level={3} textAlign="start">
                6. Links to third party sites
              </Heading>
              <Text margin={{ bottom: "15px" }}>
                6.1 The Journey Health Service may, from time to time, contain
                links to and from third party websites. If you follow a link to
                any of these websites, please note that these websites have
                their own privacy policies and that we do not accept any
                responsibility or liability for their policies. Please check the
                individual privacy policies before you submit any information to
                those websites.
              </Text>
              <Heading level={3} textAlign="start">
                7. Disclosure of your Personal Information
              </Heading>
              <Text margin={{ bottom: "15px" }}>
                7.1 We will not share your Personal Health Information except as
                set out in this Policy. We may share your Other Personal
                Information with any member of our group, which includes our
                subsidiaries. We will not share your Personal Information with
                any third parties except as described in this Policy or in
                connection with the Services. We may share your information with
                selected third parties, including:
                <br />
                <br />
                - Business partners, vendors, suppliers, and subcontractors who
                perform services on our behalf (these companies are authorized
                to use your Personal Information only as necessary to provide
                these services to us);
                <br />
                <br />- Analytics and search engine providers that assist us in
                the improvement and optimization of our Platform (these
                companies shall never be provided with access to your Personal
                Health Information);
              </Text>
              <Text margin={{ bottom: "15px" }}>
                7.2 In certain situations, we may be required to disclose
                Personal Information in response to lawful requests by public
                authorities, including to meet law enforcement requirements.
                <br />
                <br />
                If we are under a duty to disclose or share your personal data
                in order to comply with any legal obligation, or in order to
                enforce or apply our terms of use and other agreements; or to
                protect the rights, property, or safety of Journey Health, our
                customers, or others. This includes exchanging information with
                other companies and organizations for the purposes of fraud
                protection.
              </Text>
              <Text margin={{ bottom: "15px" }}>
                7.3 We may disclose Personal Information to third parties in
                connection with a business transaction. Personal Information may
                be disclosed to third parties in connection with a transaction,
                such as a merger, sale of assets or shares, reorganization,
                financing, change of control or acquisition of all or a portion
                of our business. If we are involved in a merger, acquisition, or
                sale of all or a portion of its assets, you will be notified via
                email and/or a prominent notice on our Platform of any change in
                ownership that impacts the use of your Personal Information, as
                well as any choices you may have regarding your Personal
                Information.
              </Text>
              <Heading level={3} textAlign="start">
                8. Marketing and advertising
              </Heading>
              <Text margin={{ bottom: "15px" }}>
                8.1 From time to time we may contact you with relevant
                information about our Services and our other products and
                services. Most messages will be sent electronically. For some
                messages, we may use Personal Information we collect about you
                to help us determine the most relevant information to share with
                you.
              </Text>
              <Text margin={{ bottom: "15px" }}>
                8.2 If you do not want to receive such messages from us, you
                will be able to tell us by selecting certain boxes on forms we
                use when we first collect your contact details. You can also
                change your marketing preferences at any time by following the
                unsubscribe link at the bottom of our emails.
              </Text>
              <Heading level={3} textAlign="start">
                9. Storing and transferring your Personal Information
              </Heading>
              <Text margin={{ bottom: "15px" }}>
                9.1 Journey Health has implemented administrative, technical,
                and physical safeguards to protect its and its customers
                information. Where we have given you (or where you have chosen)
                a password which enables you to access certain parts of our
                Services, you are responsible for keeping this password
                confidential. You should not share your password with anyone.
              </Text>
              <Text margin={{ bottom: "15px" }}>
                9.2 While no transmission of information via the internet is
                completely secure, we take reasonable measures to protect your
                Personal Information. We cannot guarantee the security of your
                Personal Information transmitted to our Platform; any
                transmission is at your own risk. Once we have received your
                information, we will use strict procedures and security features
                to try to prevent unauthorized access.
              </Text>
              <Text margin={{ bottom: "15px" }}>
                9.3 To ensure the security of your Personal Health Information,
                we maintain an electronic record of:
                <br />
                <br />
                a. access to your Personal Health Information by our employees,
                Health Service Providers and any person acting on our behalf;
                and
                <br />
                <br />
                b. transfers of all or part of your Personal Health Information
                to external health professionals (those that are not providing
                health services to you via our Platform).
              </Text>
              <Heading level={3} textAlign="start">
                10. Retaining your information
              </Heading>
              <Text margin={{ bottom: "15px" }}>
                10.1 We will only retain your Personal Information for as long
                as necessary to fulfill the purposes we collected it for,
                including for the purposes of our legitimate business interests
                and satisfying any legal or reporting requirements.
              </Text>
              <Text margin={{ bottom: "15px" }}>
                10.2 To determine the appropriate retention period for Personal
                Information, we consider the amount, nature, and sensitivity of
                the Personal Information, the potential risk of harm from
                unauthorized use or disclosure of your Personal Information, the
                purposes for which we process your Personal Information and the
                applicable legal requirements.
              </Text>
              <Heading level={3} textAlign="start">
                11. Your rights in respect of your Personal Information
              </Heading>
              <Text margin={{ bottom: "15px" }}>
                11.1 We value your privacy and our goal is to provide you with a
                clear and transparent framework for the use of your Personal
                Information. To that end, you have the following rights in
                respect of the Personal Information that we hold and have
                control over:
                <br />
                <br />
                -Right of access and portability. The right to obtain access to
                your Personal Information and to receive that Personal
                Information in a commonly used format.
                <br />
                <br />
                -Right to rectification. The right to obtain rectification of
                your Personal Information without undue delay where that
                Personal Information is inaccurate or incomplete.
                <br />
                <br />
                -Right to erasure. The right to obtain the erasure of your
                Personal Information without undue delay in certain
                circumstances, such as where the Personal Information is no
                longer necessary in relation to the purposes for which it was
                collected or processed.
                <br />
                <br />
                -Right to restriction. The right to obtain the restriction of
                the processing undertaken by us on your Personal Information in
                certain circumstances, such as where the accuracy of the
                Personal Information is contested by you.
              </Text>
              <Text margin={{ bottom: "15px" }}>
                11.2 If you wish to exercise one of these rights, please contact
                us using the contact details at the end of this Policy. Upon
                request, we will provide you with information about whether we
                hold any of your Personal Information. You may access, correct
                or request deletion of your Personal Information by contacting
                us at info@journeyhealth.ca. We will respond to your request
                within 30 days.
              </Text>
              <Text margin={{ bottom: "15px" }} weight="bold">
                11.3 We do have the authority to delete or otherwise dispose of
                PERSONAL INFORMATION (INCLUDING personal health information) we
                receive about you from your Health Service Providers. Such
                information is under the control of the Health Services Provider
                and any requests to obtain, modify or delete such information
                must be made through the Health Service Provider.
              </Text>
              <Text margin={{ bottom: "15px" }}>
                11.4 Journey Health does not sell Personal Information shared by
                you. All use of Personal Information is done for the delivery,
                use, and improvement of our Services.
              </Text>
              <Heading level={3} textAlign="start">
                12. Our policy towards children and other dependents
              </Heading>
              <Text margin={{ bottom: "15px" }}>
                12.1 We are committed to protecting the privacy of children and
                those who lack the necessary capacity to give valid consent to
                his or her medical treatment Policy (each such person being a
                “Dependent”). A Dependent is not permitted to use our Platform.
                However, a Dependent may receive Health Services via our
                Platform through their parent’s or legal guardian, provided that
                the parent or legal guardian is with the Dependent when the
                Dependent is receiving Health Services through our Platform. If
                you are a parent or legal guardian of a Dependent, you represent
                and warrant to us that you are authorized to provide us with
                Personal Information about the Dependent and that you have the
                authority to make medical decisions on behalf of the Dependent.
              </Text>
              <Text margin={{ bottom: "15px" }}>
                12.2 Ultimately, your Health Service Provider shall reserve the
                right to assess whether a person has the capacity to provide
                consent to his or her medical treatment on a case-by-case basis.
                If the Health Service Provider determines that a person is a
                Dependent, the Dependent’s parent or legal guardian must abide
                by the terms defined in this Policy and our Terms of Service
                whenever the Dependent receives Health Services through our
                Platform.
              </Text>
              <Heading level={3} textAlign="start">
                13. Changes to this policy
              </Heading>
              <Text margin={{ bottom: "15px" }}>
                We may update this Policy from time to time and so you should
                review this page periodically. When we change this Policy in a
                material way, we will update the “last modified” date at the end
                of this Policy. Changes to this Policy are effective when they
                are posted on this page.
              </Text>
              <Heading level={3} textAlign="start">
                14. Notice to you
              </Heading>
              <Text margin={{ bottom: "15px" }}>
                If we need to provide you with information about something,
                whether for legal, marketing or other business related purposes,
                we will select what we believe is the best way to get in contact
                with you. We will usually do this through email or by placing a
                notice on our Platform. The fact that we may send notices to you
                will not stop you from being able to opt out of certain types of
                contact as described in this Policy.
              </Text>
              <Heading level={3} textAlign="start">
                15. Contacting us
              </Heading>
              <Text margin={{ bottom: "15px" }}>
                Any questions, comments, and requests regarding this Policy are
                welcome and should be addressed to our Data Protection Officer,
                info@journeyhealth.ca. Communication can also be addressed to:
                <br />
                <br />
                Journey Health <br /> 88 Blue Jays Way, Unit 3603, Toronto, ON,
                M5V 0L7
              </Text>
            </Box>
          </Main>
        )}
      </ResponsiveContext.Consumer>
    );
  };

  return (
    <ResponsiveContext.Consumer>
      {(size) => (
        <Grid
          rows={[
            isSmallScreen(size) ? "xsmall" : "small",
            "flex",
            ["medium", "450px"],
          ]}
          columns={[["small", "flex"]]}
          areas={[
            { name: "header", start: [0, 0], end: [1, 0] },
            { name: "main", start: [0, 1], end: [1, 1] },
            { name: "footer", start: [0, 2], end: [1, 2] },
          ]}
          fill="horizontal"
          justify="center"
        >
          <GlobalHeader clinic={clinic} />
          <Body />
          <GlobalFooter clinic={clinic} />
        </Grid>
      )}
    </ResponsiveContext.Consumer>
  );
};
