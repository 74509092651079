import React, { useEffect, useState } from "react";
import firebase from "firebase";
import * as firestore from "../services/firestore";
import { useParams, useSearchParams } from "react-router-dom";
import {
  Grid,
  Heading,
  Text,
  ResponsiveContext,
  Box,
  Button,
  Spinner,
  TextInput,
  Card,
  CardBody,
  ThemeContext,
  DateInput,
} from "grommet";
import { isSmallScreen } from "../helpers/ScreenHelpers";
import { GlobalHeader } from "../components/GlobalHeader";
import { GlobalFooter } from "../components/GlobalFooter";
import { IClinic, IClinicService, ISex } from "../services/types";
import { OptionCard } from "../components/OptionCard";
import moment from "moment";
import _ from "lodash";
import { ServiceCard } from "../components/ServiceCard";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { formatPhone } from "../helpers/formatPhone";
import { DATE_FORMAT } from "../consts";

interface IPatientForm {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  dateOfBirth: string;
  healthCardNumber?: string;
  healthCardVersionCode?: string;
}

export const Book: React.FC = () => {
  const [clinic, setClinic] = useState<IClinic>({} as IClinic);
  const [services, setServices] = useState<IClinicService[]>([]);
  const [selectedService, setSelectedService] = useState<IClinicService>();
  const [availability, setAvailability] = useState<any>();
  const [selectedDate, setSelectedDate] = useState<string>();
  const [selectedApptTime, setSelectedApptTime] = useState<string>();
  const [patientFormValues, setPatientFormValues] = useState<IPatientForm>(
    {} as IPatientForm
  );

  const [isLoadingClinic, setIsLoadingClinic] = useState<boolean>(true);
  const [isLoadingAvailability, setIsLoadingAvailability] =
    useState<boolean>(true);
  const [isBooking, setIsBooking] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const { clinicName } = useParams();

  const fetchClinicData = async () => {
    setIsLoadingClinic(true);
    try {
      const clinic = await firestore.getClinicByName(clinicName || "");
      document.title = `Book your Appointment - ${clinic.displayName}`;

      const requestedServiceIds = searchParams.get("serviceIds")?.split(",");
      let services: IClinicService[] = [];
      if (requestedServiceIds && !_.isEmpty(requestedServiceIds)) {
        await Promise.all(
          requestedServiceIds.map(async (serviceId) => {
            const service = await firestore.getServiceById(
              clinic.id,
              serviceId
            );
            services.push(service);
          })
        );
      } else {
        services = await firestore.getAllServicesForClinic(clinic.id);
      }

      if (services.length === 1) {
        setSelectedService(services[0]);
      }
      setClinic(clinic);
      setServices(services);
    } catch (err) {
      console.log(`Error setting page data: ${err}`);
    }
    setIsLoadingClinic(false);
  };

  const fetchServiceAvailability = async () => {
    setIsLoadingAvailability(true);
    if (clinic.id && selectedService?.id) {
      const result = await firestore.getServiceOpenings(
        clinic.id,
        selectedService.id
      );
      setAvailability(result);
      setIsLoadingAvailability(false);
    } else {
      setSelectedDate(undefined);
      setSelectedApptTime(undefined);
    }
  };

  useEffect(() => {
    fetchClinicData();
  }, [clinicName]);

  useEffect(() => {
    fetchServiceAvailability();
  }, [clinic, selectedService]);

  const updatePatientForm = (values: any) => {
    setPatientFormValues({ ...patientFormValues, ...values });
  };

  const validateForm = () => {
    if (
      !patientFormValues.firstName ||
      !patientFormValues.lastName ||
      !patientFormValues.email ||
      !patientFormValues.phone ||
      !patientFormValues.dateOfBirth
    ) {
      toast.error("Oops! Please provide all contact details.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }
    return true;
  };

  const bookPatient = async () => {
    if (!validateForm()) {
      return;
    }

    setIsBooking(true);
    const createRequest: firestore.IBookingCreatePatientAndAppointmentRequest =
      {
        clinicId: clinic.id,
        firstName: patientFormValues.firstName,
        lastName: patientFormValues.lastName,
        phoneNumber: {
          primary: patientFormValues.phone,
          home: "",
          work: "",
          cell: "",
        },
        email: patientFormValues.email || "",
        dateOfBirth: moment(patientFormValues.dateOfBirth).format(DATE_FORMAT),
        healthCard: {
          number: `${patientFormValues.healthCardNumber || ""}${
            patientFormValues.healthCardVersionCode || ""
          }`.toUpperCase(),
        },
        serviceId: selectedService?.id || "",
        startAt: firebase.firestore.Timestamp.fromDate(
          moment(selectedApptTime).toDate()
        ),
      };

    await firestore.createPatientAndAppointment(createRequest);
    setIsBooking(false);

    window.location.replace(`https://www.${clinic.landingUrl}/booking-success`);
  };

  const DateTimePicker = () => {
    return (
      <>
        {selectedService &&
          (isLoadingAvailability ? (
            <Box align="center" justify="center" height="200px">
              <Spinner size="medium" />
            </Box>
          ) : (
            <>
              <Heading level="3">Select Appointment Date & Time</Heading>
              <Box direction="row" wrap={true} justify="center">
                {selectedDate ? (
                  <OptionCard
                    title={moment(selectedDate).format("dddd, MMM D")}
                    value={selectedDate}
                    isActive={true}
                    selectCallback={setSelectedDate}
                    width="medium"
                  />
                ) : (
                  !_.isEmpty(availability) &&
                  Object.keys(availability).map((date) => (
                    <OptionCard
                      title={moment(date).format("ddd, MMM D")}
                      value={date}
                      isActive={selectedDate === date}
                      selectCallback={setSelectedDate}
                    />
                  ))
                )}
              </Box>
            </>
          ))}
        {selectedDate && (
          <Box direction="row" wrap={true} justify="center">
            {!_.isEmpty(availability[selectedDate]) &&
              availability[selectedDate].map((datetime: string) => (
                <OptionCard
                  title={moment(datetime).format("h:mm A")}
                  value={datetime}
                  isActive={selectedApptTime === datetime}
                  selectCallback={setSelectedApptTime}
                />
              ))}
          </Box>
        )}
      </>
    );
  };

  const PatientDataForm = () => {
    return (
      <ResponsiveContext.Consumer>
        {(size) => (
          <>
            <Heading level="3">Select Appointment Date & Time</Heading>
            <Box direction="row" wrap={true} justify="start">
              <OptionCard
                title={moment(selectedApptTime).format(
                  "ddd, MMM D Y [at] h:mm A"
                )}
                subtitle="Please make sure you are ready 5 minutes before"
                value={selectedDate}
                isActive={true}
                selectCallback={() => {
                  setSelectedApptTime(undefined);
                  setSelectedDate(undefined);
                }}
                width="medium"
                margin="medium"
              />
            </Box>
            <Heading level="3">Contact Details</Heading>
            <Card
              height="auto"
              width="100%"
              justify="center"
              align="center"
              margin="xsmall"
              elevation="medium"
              background="#FFF"
              style={{ transition: "all 0.2s" }}
            >
              <CardBody pad="medium">
                <Box
                  direction="row"
                  gap={isSmallScreen(size) ? "none" : "small"}
                  wrap
                >
                  <Box
                    direction="row"
                    gap="small"
                    margin={{ bottom: "small" }}
                    width={isSmallScreen(size) ? "100%" : undefined}
                  >
                    <TextInput
                      size={isSmallScreen(size) ? "xsmall" : "small"}
                      placeholder="First Name"
                      value={patientFormValues.firstName}
                      onChange={(event) =>
                        updatePatientForm({ firstName: event.target.value })
                      }
                    />
                    <TextInput
                      size={isSmallScreen(size) ? "xsmall" : "small"}
                      placeholder="Last Name"
                      value={patientFormValues.lastName}
                      onChange={(event) =>
                        updatePatientForm({ lastName: event.target.value })
                      }
                    />
                  </Box>
                  <Box
                    direction="row"
                    gap="small"
                    margin={{ bottom: "small" }}
                    width={isSmallScreen(size) ? "100%" : undefined}
                  >
                    <TextInput
                      size={isSmallScreen(size) ? "xsmall" : "small"}
                      placeholder="Email Address"
                      value={patientFormValues.email}
                      onChange={(event) =>
                        updatePatientForm({ email: event.target.value })
                      }
                    />
                    <TextInput
                      size={isSmallScreen(size) ? "xsmall" : "small"}
                      placeholder="Phone Number"
                      value={formatPhone(patientFormValues?.phone)}
                      onChange={(event) =>
                        updatePatientForm({
                          phone: event?.target?.value
                            .replace(/\D/g, "")
                            .substring(0, 10),
                        })
                      }
                    />
                  </Box>
                </Box>
                <Box
                  direction="row"
                  gap={isSmallScreen(size) ? "none" : "small"}
                  wrap
                >
                  <DateInput
                    placeholder="Date of Birth (MM/DD/YYYY)"
                    format="mm/dd/yyyy"
                    size={isSmallScreen(size) ? "xsmall" : "small"}
                    value={patientFormValues.dateOfBirth}
                    onChange={(event) => {
                      patientFormValues.dateOfBirth = event.value as string;
                    }}
                    icon={<></>}
                  />
                </Box>
                <Heading level={4}>Health Card (Optional)</Heading>
                <Box direction="row" gap="small" margin={{ bottom: "40px" }}>
                  <TextInput
                    size={isSmallScreen(size) ? "xsmall" : "small"}
                    placeholder="Health Card Number"
                    value={patientFormValues.healthCardNumber}
                    onChange={(event) =>
                      updatePatientForm({
                        healthCardNumber: event?.target?.value
                          .replace(/\D/g, "")
                          .substring(0, 10),
                      })
                    }
                  />
                  <TextInput
                    size={isSmallScreen(size) ? "xsmall" : "small"}
                    placeholder="2 Letter Version Code"
                    value={patientFormValues.healthCardVersionCode}
                    onChange={(event) =>
                      updatePatientForm({
                        healthCardVersionCode: event?.target?.value
                          .replace(/[0-9]/g, "")
                          .substring(0, 2)
                          .toUpperCase(),
                      })
                    }
                  />
                </Box>
                <Box align="center">
                  <Button
                    primary
                    label={
                      <Box direction="row">
                        {isBooking && (
                          <Spinner
                            color="#FFF"
                            size="xsmall"
                            margin={{ left: "-5px", right: "10px" }}
                          />
                        )}
                        <Text weight="bold" textAlign="center" color="white">
                          Book Now
                        </Text>
                      </Box>
                    }
                    onClick={bookPatient}
                    disabled={isBooking}
                  />
                </Box>
              </CardBody>
            </Card>
          </>
        )}
      </ResponsiveContext.Consumer>
    );
  };

  const MainBody = () => {
    return (
      <ResponsiveContext.Consumer>
        {(size) => (
          <Box
            width={{ width: "100%", max: "1000px" }}
            pad={{
              horizontal: isSmallScreen(size) ? "medium" : "large",
            }}
            justify="center"
          >
            <ToastContainer />
            <Heading level="3">Select Service</Heading>

            <Box
              direction="row"
              wrap={true}
              justify={selectedService ? "start" : "center"}
            >
              {selectedService ? (
                <ServiceCard
                  service={selectedService}
                  isActive={true}
                  selectCallback={setSelectedService}
                />
              ) : (
                services.map((service) => (
                  <ServiceCard
                    service={service}
                    isActive={selectedService === service}
                    selectCallback={setSelectedService}
                  />
                ))
              )}
            </Box>

            {!selectedDate || !selectedApptTime ? (
              <DateTimePicker />
            ) : (
              PatientDataForm()
            )}
          </Box>
        )}
      </ResponsiveContext.Consumer>
    );
  };

  return (
    <ThemeContext.Extend value={(clinic.theme as any) || {}}>
      <ResponsiveContext.Consumer>
        {(size) =>
          isLoadingClinic ? (
            <Box
              align="center"
              alignSelf="center"
              justify="center"
              fill="vertical"
            >
              <Spinner size="medium" />
            </Box>
          ) : (
            <Grid
              rows={["xsmall", "flex", ["medium", "450px"]]}
              columns={[["small", "flex"]]}
              areas={[
                { name: "header", start: [0, 0], end: [1, 0] },
                { name: "main", start: [0, 1], end: [1, 1] },
                { name: "footer", start: [0, 2], end: [1, 2] },
              ]}
              fill="horizontal"
              justify="center"
              style={{ overflow: "hidden" }}
            >
              <GlobalHeader clinic={clinic} />
              {MainBody()}
              <GlobalFooter clinic={clinic} />
            </Grid>
          )
        }
      </ResponsiveContext.Consumer>
    </ThemeContext.Extend>
  );
};
