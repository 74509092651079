import React from "react";
import { Footer, Heading, ResponsiveContext, Box, Text, Button } from "grommet";
import { isSmallScreen } from "../helpers/ScreenHelpers";
import { Link } from "react-router-dom";
import { ShieldSecurity } from "grommet-icons";
import { IClinic } from "../services/types";
import { formatPhone } from "../helpers/formatPhone";

type IProps = {
  clinic: IClinic;
};

export const GlobalFooter: React.FC<IProps> = ({ clinic }: IProps) => {
  return (
    <ResponsiveContext.Consumer>
      {(size) => (
        <Footer
          gridArea="footer"
          width={{ width: "100%" }}
          pad={{ vertical: "large" }}
          justify="center"
        >
          <Box
            direction={isSmallScreen(size) ? "column" : "row"}
            justify={isSmallScreen(size) ? "start" : "between"}
            align={isSmallScreen(size) ? "center" : "start"}
            width={{ width: "100%", max: "1400px" }}
            margin={{
              horizontal: isSmallScreen(size) ? "xsmall" : "large",
            }}
            pad={{
              top: isSmallScreen(size) ? "xlarge" : "none",
            }}
          >
            <Box direction="column" margin={{ bottom: "30px" }}>
              <Box width="450px" margin={{ bottom: "10px" }}>
                <Text
                  size="xsmall"
                  textAlign={isSmallScreen(size) ? "center" : "start"}
                >
                  For medical emergencies, please call 911
                </Text>
              </Box>
              <Box
                width="450px"
                direction="row"
                align="center"
                justify={isSmallScreen(size) ? "center" : undefined}
              >
                <Box margin={{ right: "7px" }}>
                  <ShieldSecurity />
                </Box>
                <Box>
                  <Text size="xsmall" textAlign="start">
                    All data secured using SSL by Journey Health
                    <br />
                    in compliance with HIPAA and PHIPA regulations
                  </Text>
                </Box>
              </Box>
              <Box width="100%">
                <Link
                  to={{ pathname: "//www.journeyhealth.ca" }}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "#000",
                    textDecoration: "none",
                  }}
                >
                  <Heading
                    size="14px"
                    textAlign={isSmallScreen(size) ? "center" : "start"}
                  >
                    Powered by Journey Health{" "}
                    <Text size="10px">© 2022. All Rights Reserved</Text>
                  </Heading>
                </Link>
              </Box>
            </Box>
            <Box direction="column">
              <Box
                align={isSmallScreen(size) ? "center" : "end"}
                margin={{ bottom: "10px" }}
              >
                <Text weight="bold" size="xsmall">
                  Contact
                </Text>
                <Text size="xsmall">
                  {clinic.phoneNumber
                    ? formatPhone(clinic.phoneNumber)
                    : "+1 (289) 923-8161"}
                </Text>
                <Text size="xsmall">
                  {clinic.email ? clinic.email : "info@journeyhealth.ca"}
                </Text>
              </Box>
              <Box align={isSmallScreen(size) ? "center" : "end"}>
                <Link
                  to={`/privacy-policy/${clinic.name}`}
                  style={{ color: "#000" }}
                >
                  <Text weight="bold" size="small" color="#000">
                    Privacy Policy
                  </Text>
                </Link>
              </Box>
            </Box>
          </Box>
        </Footer>
      )}
    </ResponsiveContext.Consumer>
  );
};
